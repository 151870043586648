import React from 'react'
import { Box, Input } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'

const FormInput = ({ placeholder, icon, ...props }) => (
  <Box variant='forms.field'>
    <Icon content={icon} size='xs' round ml='3' pl='0' />

    {(props.id.includes("TEXTFILE"))? 
    <input type="file" {...props} type={"file"} accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
    : <Input
      {...props}
      type={props.type.toLowerCase()}
      placeholder={placeholder?.text}/>}

   
    
    
  </Box>
)
export default FormInput
