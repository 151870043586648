import React from 'react'

export default function SelectOptions({ optionsList, ...props}) {
  return (
    <>
      {optionsList?.map((value, index) => {
        return (<option key={index} value={value}>{value}</option>)
      }
      )}

    </>
  )
}
