const axios = require('axios');
import { getObjectFromEntry } from '../../../common/util'


export const normalizeBlockContentNodes = (allContent, key = 'identifier') =>
  allContent?.reduce((normalized, block) => {
    normalized[block[key]] = block
    return normalized
  }
    , {})
