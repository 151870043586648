import React from 'react'
import { Box, Input } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'

const CapchaFormInput = ({ placeholder, icon, ...props }) => (
  <Box variant='forms.field' >
    <Icon content={icon} size='xs' round ml='3' pl='0' />
    <Input
      {...props}
      type='text'
      placeholder={placeholder?.text}
      autoComplete="off"
    />
  </Box>
)
export default CapchaFormInput
