import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, css, Spinner } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import CapchaFormInput from '@solid-ui-components/ContentForm/CapchaFormInput'
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea'
import FormHidden from '@solid-ui-components/ContentForm/FormHidden'
import FormSelect from '../../../../themes/gatsby-theme-flexiblocks/src/components/FormSelect'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import { useMediaQueryContext } from '@solid-ui-components/MediaQueryProvider'

const axios = require('axios');
const capchaData = require('./capcha.json');
var Buffer = require('buffer/').Buffer


const styles = {
  form: {
    position: `relative`
  },
  responseOverlay: {
    position: `absolute`,
    backgroundColor: `transparent`,
    width: `full`,
    height: `105%`,
    transition: `background-color 350ms ease-in`,
    textAlign: `center`,
    zIndex: -1,
    p: 3,
    top: 0,
    left: 0,
    active: {
      zIndex: 0,
      backgroundColor: `rgba(255,255,255,0.85)`
    }
  },
  buttonsWrapper: {
    display: `inline-flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    '.button-group-button + .button-group-link': {
      flex: `100%`,
      ml: 0,
      mt: 1
    }
  }
}

const ContentForm = ({ id, form: { action, fields, buttons } = {} }) => {
  const { isMobile } = useMediaQueryContext()
  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = id

  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submitting, setFormValues])

  const onChange = e => {
    console.log(e);
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }
    })
  }

  let random = Math.floor(Math.random() * capchaData.length);
  if (window.sessionStorage.getItem("random")) {
    random = window.sessionStorage.getItem("random");
  } else {
    window.sessionStorage.setItem("random", random);
  }
  const capchaRead = capchaData[random];
  const onSubmit = e => {

    e.preventDefault();

    const findTerm = (fullText, term) => {
      if (fullText.includes(term)) {
        return true;
      } else {
        return false;
      }
    };

    const formData = new URLSearchParams(formValues[formId]).toString();
    console.log(formData);
    let obj = Object.fromEntries(new URLSearchParams(formData));

    if (findTerm(formId, 'getintouch')) {
      var msg = JSON.stringify({
        subject: "DEFRAUDIT-SITE:GET IN TOUCH",
        formdata: formData
      });

      if (capchaRead.answer.toString() == obj.capcha) {
        sessionStorage.removeItem("random");
        axios.post('/.netlify/functions/mail', msg);
        handleSubmit(e, { values: formValues[formId], action })
      } else {
        const capchaID = window.sessionStorage.getItem("capchaID");
        document.getElementById(capchaID).value = '';
        document.getElementById(capchaID).focus();
        alert("Invalid answer for:\nWhat is " + capchaRead.left + " " + capchaRead.formula + " " + capchaRead.right + " ?");
      }
    } else if (findTerm(formId, 'contact')) {
      var msg = JSON.stringify({
        subject: "DEFRAUDIT-SITE:CONTACT",
        formdata: formData
      });

      if (capchaRead.answer.toString() == obj.capcha) {
        sessionStorage.removeItem("random");
        axios.post('/.netlify/functions/mail', msg);
        handleSubmit(e, { values: formValues[formId], action })
      } else {
        const capchaID = window.sessionStorage.getItem("capchaID");
        document.getElementById(capchaID).value = '';
        // document.getElementById(random).style.color="red";
        document.getElementById(capchaID).focus();
        alert("Invalid answer for:\nWhat is " + capchaRead.left + " " + capchaRead.formula + " " + capchaRead.right + " ?");
      }
    }else if (findTerm(formId, 'joinus')) {
      var msg = JSON.stringify({
        subject: "DEFRAUDIT-SITE:joinus",
        formdata: formData
      });

      if (capchaRead.answer.toString() == obj.capcha) {
        sessionStorage.removeItem("random");        
        axios.post('/.netlify/functions/mail', msg);
        handleSubmit(e, { values: formValues[formId], action })
      } else {
        const capchaID = window.sessionStorage.getItem("capchaID");
        document.getElementById(capchaID).value = '';
        // document.getElementById(random).style.color="red";
        document.getElementById(capchaID).focus();
        alert("Invalid answer for:\nWhat is " + capchaRead.left + " " + capchaRead.formula + " " + capchaRead.right + " ?");
      }


    } else if (findTerm(formId, 'enquire')) {
      var msg = JSON.stringify({
        subject: `DEFRAUDIT-SITE:${formId.toUpperCase().split('.')[1].split('_').join(' ')}`,
        formdata: formData
      });

      if (capchaRead.answer.toString() == obj.capcha) {
        sessionStorage.removeItem("random");
        axios.post('/.netlify/functions/mail', msg);
        handleSubmit(e, { values: formValues[formId], action })
      } else {
        const capchaID = window.sessionStorage.getItem("capchaID");
        document.getElementById(capchaID).value = '';
        // document.getElementById(random).style.color="red";
        document.getElementById(capchaID).focus();
        alert("Invalid answer for:\nWhat is " + capchaRead.left + " " + capchaRead.formula + " " + capchaRead.right + " ?");
      }
    } else {
      console.log(formId);
      handleSubmit(e, { values: formValues[formId], action })
    }
  }

  function parseBody(body, isBase64Encoded) {
    if (body instanceof Buffer) {
      return (body);
    }
    if (isBase64Encoded) {
      return (Buffer.from(body, "base64"));
    }
    return (Buffer.from(body));
  }


  return (
    <form
      css={css(styles.form)}
      onSubmit={onSubmit}
      name={formId}
      data-netlify-honeypot="bot-field"
      method='POST'
      // action={action}
      enctype="multipart/form-data"
      demo={action ? undefined : 'demo'}
      data-netlify="true"
      autoComplete="off"
    >
      <Box variant='forms.row'>
        {fields?.map(({ identifier, value, ...props }, index) => {
          let Component
          switch (props.type) {
            case 'PASSWORD':
            case 'EMAIL':
            case 'TEXT':
              Component = FormInput
              break
            case 'CAPCHA': {
              window.sessionStorage.setItem("capchaID", formId + "." + identifier);
              props.placeholder.text = "What is " + capchaRead.left + " " + capchaRead.formula + " " + capchaRead.right + " ?"
              Component = CapchaFormInput
              break
            }
            case 'TEXTAREA':
              Component = FormTextarea
              break
            case 'CHECKBOX':
              Component = FormCheckbox
              break
            case 'HIDDEN':
              Component = FormHidden
              break
            case 'SELECT':
              Component = FormSelect
              break
            default:
              break
          }


          return (
            <Box
              key={`index-${index}`}
              variant={props.compact ? 'forms.compact' : 'forms.full'}

            >
              <Component
                {...props}
                onChange={onChange}
                name={identifier}
                id={`${formId}.${identifier}`}
                value={formValues?.[formId]?.[identifier] || value || undefined}
                css={css({ height: '40px' })}

              />
            </Box>
          )
        })}

      </Box>
      <Box sx={{ textAlign: `center`, mt: isMobile ? 20: 1, mb: isMobile ? '6px' : 0 }}>
        <ContentButtons
          content={buttons}
        />
      </Box>
      <Box

        sx={styles.responseOverlay}
        css={(submitting || success) && styles.responseOverlay.active}
      >
        {submitting && (
          <Reveal effect='fadeInDown'>
            <Spinner size='64' color='alpha' />
          </Reveal>
        )}
        {success === true && (
          <Reveal effect='fadeInDown'>
            <BiCheckCircle size='64' css={css({ color: `success` })} />
          </Reveal>
        )}
        {success === false && (
          <BiErrorCircle size='64' css={css({ color: `error` })} />
        )}
      </Box>
    </form>
  )
}

export default ContentForm

ContentForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}