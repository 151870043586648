import React from 'react'
import { Box, Select, css, Input } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import SelectOptions from './SelectOptions'
const styles = {
  wrapper:{
    border:"none",
    '&:focus-visible': { outline: 'none' },
    
  }

}

const FormSelect = ({ placeholder  , icon, collection, ...props }) => {
  const OptionsString = placeholder?.text;
  const optionList = OptionsString?.split(',,');
  const theSelectFormName = (optionList) ? optionList[0]:'';
return (
  <Box  variant='forms.field'>
    
    <Icon content={icon} size='xs' round ml='3' pl='0' />
    <Select 
      type="select"
      name={props.name}   
      sx={{
        width: ['435px', '345px', '332px'],
      }}
      placeholder={theSelectFormName} css={css(styles.wrapper)}
      defaultValue={theSelectFormName} {...props}>
      <SelectOptions optionsList={optionList} /> 
      
      
      
    </Select>


  </Box>
)
}

export default FormSelect
