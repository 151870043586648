import React, { createContext, useContext, useMemo } from 'react'
import useMedia from 'use-media'

export const MediaQueryContext = createContext({
  isMobile: false,
  isTablet: false
})

const mediaQueries = {
  mobile: '(max-width: 768px)',
  tablet: '(max-width: 992px)'
}

export const MediaQueryProvider = ({ children }) => {
  const isMobile = useMedia(mediaQueries.mobile)
  const isTablet = useMedia(mediaQueries.tablet)
  const value = useMemo(() => ({
      isMobile,
      isTablet
    }),[isMobile, isTablet])

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  )
}

export const useMediaQueryContext = () => useContext(MediaQueryContext)
